<template>
  <div class="home" :style="{ opacity: homeOpacity }">
    <!--  缓存图片  -->
    <img src="../assets/img/projectRight.jpg" style="display: none" />
    <img src="../assets/img/projectLeft.jpg" style="display: none" />
    <Notice></Notice>
    <div class="top-area-wrap">
      <TopBar
        @menuMouseover="menuMouseover"
        @menuMouseleave="menuMouseleave"
        ref="topCom"
        :userInfo="userInfo"
        :isBlack="projectMenuShow"
      />
      <ProjectMenu
        class="project-menu-area"
        v-show="projectMenuShow"
        :hiddenFun="hiddenProjectMenu"
        :stopFun="stopFun"
        :menuList="menuList"
      />
    </div>

    <Banner v-show="homeModule.轮播图 == 1"></Banner>

    <ProjectNav
      v-if="
        JSON.stringify(homeModule) != '{}' &&
        (homeModule.产品架构 != 0 ||
          homeModule.产品优势 != 0 ||
          homeModule.产品矩阵 != 0)
      "
      :homeModule="homeModule"
      :menuCode.sync="currProjectCode"
      @update:menuCode="selectProject"
    ></ProjectNav>

    <Jiagou
      ref="jiagou"
      v-if="homeModule.产品架构 == 1"
      v-scrollselect="{
        callback: () => scrollTrigger('jiagou'),
      }"
    ></Jiagou>
    <Youshi
      ref="youshi"
      v-show="homeModule.产品优势 == 1"
      v-scrollselect="{
        callback: () => scrollTrigger('youshi'),
      }"
    ></Youshi>
    <Juzhen
      ref="juzhen"
      v-show="homeModule.产品矩阵 == 1"
      v-scrollselect="{
        callback: () => scrollTrigger('juzhen'),
      }"
    ></Juzhen>

    <Anli v-show="homeModule.平台案例 == 1"></Anli>
    <Slogan v-show="homeModule.页尾 == 1"></Slogan>
    <BottomInfo v-show="homeModule.页尾 == 1" :menuList="menuList"></BottomInfo>
  </div>
</template>

<script>
import { findAll2, homePageModule, getUserInfo } from "@/server/api";

// @ is an alias to /src
import Notice from "@/components/Notice.vue";
import TopBar from "@/components/TopBar.vue";
import ProjectMenu from "@/components/ProjectMenu.vue";
import Banner from "@/components/Banner.vue";
import ProjectNav from "@/components/ProjectNav.vue";
import Jiagou from "@/components/Jiagou.vue";
import Youshi from "@/components/Youshi.vue";
import Juzhen from "@/components/Juzhen.vue";
import BottomInfo from "@/components/BottomInfo.vue";
import Anli from "@/components/Anli.vue";
import Slogan from "@/components/Slogan.vue";
export default {
  name: "Home",
  components: {
    Notice,
    TopBar,
    ProjectMenu,
    Banner,
    ProjectNav,
    Jiagou,
    Youshi,
    Juzhen,
    BottomInfo,
    Anli,
    Slogan,
  },
  data: () => {
    return {
      homeOpacity: 0,
      projectMenuShow: false,
      currProjectCode: "jiagou",
      timer: undefined,
      menuList: [],
      // 模块展示信息
      homeModule: {},
      userInfo: {},
    };
  },
  mounted() {
    setTimeout(() => {
      this.homeOpacity = 1;
    }, 250);
    // 获取用户信息
    this.getUserInfoFn();

    // 获取产品列表
    this.getfindAll2Fn();

    // 获取模块权限
    this.getModuleListFn();
  },
  methods: {
    stopFun() {
      clearTimeout(this.timer);
    },
    menuMouseover(code) {
      if (code === "produce") {
        this.projectMenuShow = true;
      }
    },
    menuMouseleave(code) {
      if (code === "produce") {
        this.timer = setTimeout(() => {
          this.projectMenuShow = false;
          this.$refs.topCom.selectedProduce(false);
        }, 200);
      }
    },
    hiddenProjectMenu() {
      this.projectMenuShow = false;
      this.$refs.topCom.selectedProduce(false);
    },
    scrollTrigger(code) {
      this.currProjectCode = code;
    },
    selectProject(code) {
      const el = this.$refs[code].$el;
      window.scrollTo({
        top: el.offsetTop,
        behavior: "smooth",
      });
    },
    getUserInfoFn() {
      getUserInfo().then((res) => {
        this.userInfo = res.data.rspData;
        localStorage.setItem("userInfo", JSON.stringify(this.userInfo));
      });
    },
    getfindAll2Fn() {
      findAll2()
        .then((res) => {
          this.menuList = res.data.rspData;
        })
        .catch((err) => {
        });
    },
    getModuleListFn() {
      homePageModule()
        .then((res) => {
          this.homeModule = res.data.rspData;
        })
        .catch((err) => {
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/style/reset.scss";
.home {
  min-width: $page_min_width;
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  .top-area-wrap {
    position: relative;
  }
}
</style>
