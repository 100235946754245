<template>
  <div class="project-menu-mask" @click="clickMask">
    <div
      class="project-menu-wraper"
      @click.stop
      @mouseenter="mouseenter"
      @mouseleave="mouseleave"
      :style="{ backgroundImage: 'url(' + projectBg + ')' }"
    >
      <div class="project-menu-scroll-area">
        <div class="desc-area">
          <div class="title">
            <div>产品中心</div>
          </div>
        </div>
        <div class="menu-area">
          <template v-for="(item, index) in projectList">
            <div class="menu-item" :key="index">
              <div class="menu-name">
                <div class="menu-span">{{ item.name }}</div>
              </div>
              <div class="project-list">
                <template v-for="(it, i) in item.projects">
                  <div class="project" :key="i" @click="clickProject(it)">
                    <div class="project-desc-area">
                      <div class="project-title">
                        {{ it.modulesName }}
                        <img
                          v-if="it.modulesName === 'D.I BDP'"
                          class="decorate"
                          :src="Hot"
                          alt=""
                        />
                      </div>
                      <div class="project-desc">{{ it.name }}</div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Hot from "../assets/img/HOT.png";
import projectBg from "../assets/img/project_bg.jpg";

export default {
  name: "ProjectMenu",
  props: {
    hiddenFun: Function,
    stopFun: Function,
    menuList: Array,
  },
  data: () => {
    return {
      Hot,
      projectBg,
      projectTypeList: [
        { label: "大数据平台", value: 1 },
        { label: "数据服务", value: 2 },
        { label: "数据应用", value: 3 },
        { label: "数据可视化", value: 7 },
        { label: "算法服务", value: 8 },
      ],
      projectList: [],
    };
  },
  watch: {
    menuList: {
      handler() {
        this.onMenuListChange();
      },
      immediate: true,
    },
  },
  created() {
    new Image(this.projectBg);
  },
  methods: {
    onMenuListChange() {
      const menuObj = this.menuList.reduce((total, item) => {
        if (total[item.groupId]) {
          total[item.groupId].push(item);
        } else {
          total[item.groupId] = [item];
        }
        return total;
      }, {});

      this.projectList = [];

      this.projectTypeList.forEach((item) => {
        this.projectList.push({
          name: item.label,
          projects: menuObj[item.value],
        });
      });

      this.projectList = this.projectList.filter((item) => {
        return item.projects && item.projects.length;
      });
    },
    mouseleave() {
      this.hiddenFun();
    },
    mouseenter() {
      this.stopFun();
    },
    computeWidth(item) {
      const count = item.projects && item.projects.length;
      let width = "auto";
      if (count) {
        const colCount = Math.floor((count - 1) / 3) + 1;

        width = colCount * 230 + "px";
      }

      return width;
    },
    clickMask() {
      this.hiddenFun && this.hiddenFun();
    },
    clickProject(data) {
      this.hiddenFun && this.hiddenFun();
      if(data.url){
        if(data.url.indexOf('https')!==-1 || data.url.indexOf('http')!==-1){
           window.open(data.url)
        }else{
           window.open(`${window.location.origin}${data.url}`);
        }
      }else if (data.id) {
        this.util.openProject(data.id);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "../assets/style/reset.scss";

.project-menu-mask {
  width: 100%;
  height: 100vh;
  background: rgba(10, 18, 35, 0.5);
  position: absolute;
  z-index: 10;

  .project-menu-wraper {
    width: 100%;
    height: fit-content;
    z-index: 10;
    border-top: 1px solid rgba(214, 217, 227, 1);

    .project-menu-scroll-area {
      display: flex;
      margin: 0 auto;
      max-width: $page_min_width;
      padding: 0 $page_width_padding;
      min-width: $page_min_width;
      width: 100%;
      box-sizing: border-box;

      .desc-area {
        width: 136px;
        flex-shrink: 0;
        border-right: 1px solid rgba(214, 217, 227, 1);
        margin: 30px 0;
        .title {
          font-size: 20px;
          font-weight: 600;
          color: rgba(57, 63, 73, 1);
        }
      }

      .menu-area {
        display: flex;
        flex-direction: column;
        padding-left: 30px;
        padding-right: 0;
        padding-bottom: 0px;
        padding-top: 26px;

        .menu-item {
          width: 100%;
          margin-bottom: 12px;
          .menu-name {
            width: 100%;
            height: 25px;
            padding-bottom: 8px;
            margin-bottom: 10px;
            border-bottom: 1px solid rgba(214, 217, 227, 1);

            .menu-span {
              font-size: 16px;
              font-weight: 500;
              width: fit-content;
              height: 100%;
              color: #393f49;
              padding-bottom: 8px;
              border-bottom: 2px solid #c90420;
            }
          }

          .project-list {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            .project {
              display: flex;
              align-items: center;
              padding-right: 20px;
              margin-bottom: 10px;
              cursor: pointer;
              width: 180px;
              height: 60px;
              img {
                width: 48px;
                margin-right: 15px;
                flex-shrink: 0;
              }

              .project-desc-area {
                flex-grow: 1;
                width: 0;
                .project-title {
                  font-size: 14px;
                  color: rgba(0, 0, 0, 1);
                  margin-bottom: 2px;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  width: 100%;
                  position: relative;

                  .decorate {
                    width: 43px;
                    position: absolute;
                    top: 2px;
                    left: 52px;
                  }
                }

                .project-desc {
                  width: 100%;
                  font-size: 13px;
                  font-weight: normal;
                  color: rgba(96, 96, 96, 1);
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                }
              }
            }
          }
        }

        .menu-item:nth-last-child(1) {
          border-right: none;
        }
      }
    }
  }
}
</style>
