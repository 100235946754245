<template>
  <div class="notice-wrap" v-if="noticeData.isNotice">
    <div class="bar" v-if="barShow">
      <i class="el-icon-warning"></i>
      {{ noticeData.noticeContent }}
      <i class="bar-close el-icon-close" @click="closeBar"></i>
    </div>
    <el-dialog :visible.sync="dialogShow">
      <p class="dialog-content">{{ noticeData.noticeContent }}</p>
    </el-dialog>
  </div>
</template>

<script>
import { getNoticeData } from "@/server/api";

export default {
  data() {
    return {
      noticeData: {
        noticeContent:
          "",
        isNotice: false,
      },
      barShow: true,
      dialogShow: true,
    };
  },
  watch: {
    dialogShow(val) {
      if (!val) {
        localStorage.setItem("noDialogShow", true);
      }
    },
  },
  mounted() {
    getNoticeData().then((res) => {
      this.noticeData = res.data.noticeData;
    });
    this.dialogShow = !localStorage.getItem("noDialogShow");
  },
  methods: {
    closeBar() {
      this.barShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/style/reset.scss";

.notice-wrap {
  .bar {
    font-size: 12px;
    color: white;
    padding: 6px $page_width_padding;
    background: #292421;

    .bar-close {
      cursor: pointer;
    }
  }

  .dialog-content {
    font-size: 18px;
    padding: 20px;
    padding-top: 0;
  }
}
</style>
