<template>
  <div class="menu-area" :class="[customClass]">
    <template v-for="(item, index) in menuList">
      <div
        class="menu-btn"
        :class="{ 'active-btn': currMenuCode === item.code }"
        :key="index"
        @click="selectMenu(item)"
        @mouseover="menuMouseover(item.code)"
        @mouseleave="menuMouseleave(item.code)"
      >
        <slot v-bind:data="item"></slot>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: ["menuList", "menuCode", "customClass"],
  watch: {
    menuCode: {
      handler(value) {
        this.currMenuCode = value;
      },
      immediate: true,
    },
  },
  data: () => {
    return {
      currMenuCode: "",
    };
  },
  methods: {
    selectMenu(item) {
      this.$emit("update:menuCode", item.code);
      if (!item.noSelect) {
        this.currMenuCode = item.code;
      }
    },
    menuMouseover(code) {
      this.$emit("menuMouseover", code);
    },
    menuMouseleave(code) {
      this.$emit("menuMouseleave", code);
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-area {
  display: flex;
  height: 100%;

  .menu-btn {
    font-size: 14px;
    font-weight: normal;
    color: rgba(255, 255, 255, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    flex-shrink: 0;
    height: 100%;
  }

  .active-btn {
    font-weight: 600;
    position: relative;

    &::before {
      content: " ";
      position: absolute;
      width: 100%;
      height: 2px;
      background: #C90420;
      bottom: 0;
    }
  }
}
</style>
