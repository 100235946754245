import { http } from "@jd/common-tool";
import { Message } from "element-ui";

function intercept(resp) {
  if (resp.data.rspCode !== "200" && resp.data.rspCode !== "000000000" && !resp.data.isConfig) {
    Message.error(resp.data.message);
  }
}

const handleResponse = (resp) => {
  intercept(resp);
  return resp;
};

const handleError = (err) => {
  const status = err.response.status;
  if (status === 401) {
    window.location.href = err.response.headers.location;
  }
  return Promise.reject(err);
};

// function mockHref() {
//   const iframe = document.createElement("iframe");
//   iframe.src = localStorage.getItem("login_url");
//   iframe.width = 0;
//   iframe.height = 0;
//   iframe.style.position = "fixed";
//   iframe.style.top = 0;
//   iframe.style.left = 0;
//   iframe.style.zIndex = -10;
//   document.body.appendChild(iframe);
//   setTimeout(() => {
//     document.body.removeChild(iframe);
//     window.location.href = window.location.href;
//   }, 200);
// }

const baseURl = "/";
const { commonApi, uploadApi, formApi } = http.quickCreateService(baseURl, {
  handleResponse,
  handleError,
});

export { commonApi, uploadApi, formApi };

export default function (Vue) {
  Vue.prototype.$http = commonApi;
}
