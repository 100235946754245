<template>
  <div class="project-nav-wraper">
    <NavMenu
      :menuList="menuList"
      v-slot="{ data }"
      :menuCode.sync="currMenuCode"
      @update:menuCode="selectMenu"
    >
      <div class="nav-item">
        <img :src="data.img" alt="" />
        <div class="right">
          <div class="name">{{ data.name }}</div>
          <div class="desc">{{ data.desc }}</div>
        </div>
      </div>
    </NavMenu>
  </div>
</template>
<script>
import NavMenu from "./NavMenu.vue";
import jiagou from "../assets/img/jiagou.png";
import youshi from "../assets/img/youshi.png";
import juzhen from "../assets/img/juzhen.png";

export default {
  components: {
    NavMenu,
  },
  props: ["menuCode", "homeModule"],
  watch: {
    menuCode: {
      handler(value) {
        this.currMenuCode = value;
      },
      immediate: true,
    },
  },
  data: () => {
    return {
      currMenuCode: "",
      menuList: [
        {
          name: "产品架构",
          desc: "打造覆盖数据全流程的一站式平台",
          img: jiagou,
          code: "jiagou",
        },
        {
          name: "产品优势",
          desc: "数据领域深耕者，打造贴心大数据平台",
          img: youshi,
          code: "youshi",
        },
        {
          name: "产品矩阵",
          desc: "助力数字化转型，帮助解决客户痛点",
          img: juzhen,
          code: "juzhen",
        },
      ],
    };
  },
  watch: {
    homeModule: {
      handler(val) {
        if (val) {
          for (let i in val) {
            this.menuList.forEach((el, index) => {
              if (el.name == i && val[i] == 0) {
                this.menuList.splice(index, 1);
              }
            });
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    selectMenu(code) {
      this.$emit("update:menuCode", code);
    },
  },
};
</script>

<style scoped lang="scss">
@import "../assets/style/reset.scss";
.project-nav-wraper {
  width: 100%;
  box-sizing: border-box;
  height: 110px;
  display: flex;
  justify-content: center;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0 2px 10px 0 rgba(236, 238, 241, 1);

  .menu-area {
    width: 100%;
    justify-content: space-between;
    padding: 0 $page_width_padding;
    max-width: $banner_max_width;
    box-sizing: border-box;
  }

  .nav-item {
    display: flex;
    align-items: center;
    height: 100%;
    img {
      width: 46px;
      height: 46px;
      margin-right: 28px;
    }

    .right {
      .name {
        height: 28px;
        font-size: 20px;
        font-weight: 600;
        color: rgba(48, 48, 48, 1);
      }
      .desc {
        font-size: 14px;
        font-weight: normal;
        color: rgba(48, 48, 48, 1);
      }
    }
  }
}
</style>
