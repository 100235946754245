<template>
  <div class="jiagou-wraper">
    <TitleStyle
      :title="'一站式、全链路、全可视化数据中台'"
      :desc="'覆盖数据处理的全生命周期、构建企业全域数据资产中心、实现数据统一、标准化、资产化、面向全业务开放赋能'"
    ></TitleStyle>
    <video
      class="bg"
      autoplay="autoplay"
      loop="loop"
      muted="muted"
      :poster="mainJpg"
    >
      <source :src="mainMp4" type="video/mp4" />
    </video>
  </div>
</template>

<script>
import TitleStyle from "./TitleStyle.vue";
import mainMp4 from "../assets/img/main.mp4";
import mainJpg from "../assets/img/main.jpg";

export default {
  components: {
    TitleStyle,
  },
  data: () => {
    return {
      mainMp4,
      mainJpg,
    };
  },
};
</script>

<style lang="scss" scoped>
.jiagou-wraper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .bg {
    width: 1100px;
    vertical-align: top;
    margin-top: 20px;
  }
}
</style>
