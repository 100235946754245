import { commonApi } from "../http";

// 获取本地通知配置
export function getNoticeData() {
  return commonApi.get("./static/config.json");
}

// 获取用户所有权限树
// http://linking.101bank.sh/api/program/find/allV2
export function findAll2() {
  return commonApi.post("api/program/find/allV2", { versionId: 1 });
}

// 首页管理页面-模块列表
export function homePageModule() {
  return commonApi.get("api/support/support/homepage/module/show");
}

// 平台名称及icon
export function platfromMessApi() {
  return commonApi.post("api/support/support/homepage/icon/gain", {
    iconId: "",
  });
}

// 轮播图展示
export function swiperListApi(params) {
  return commonApi.post("api/support/support/homepage/image/page", params);
}

// 登出
export function signOut() {
  return commonApi.post("api/user/logout");
}

// 修改密码
export function changePwdApi(params) {
  return commonApi.post("api/user/password/update", params);
}

// 获取用户信息
export function getUserInfo() {
  return commonApi.get("api/sys/query/user2");
}
