<template>
  <div class="banner-wrapper">
    <el-carousel :height="carHeight" arrow="never" :interval="5000">
      <el-carousel-item v-for="(item, index) in swiperList" :key="index">
        <div class="swiper-item" ref="swiperItem">
          <div class="text-area">
            <div class="title">{{ item.imageName }}</div>
            <div class="desc">{{ item.description }}</div>
            <div
              class="btn"
              v-if="item.jumpURL"
              @click="clickProject(item.jumpURL)"
            >
              立即使用
            </div>
          </div>
          <video
            v-if="item.type != 0"
            class="swiper-bg"
            ref="swiperVideo"
            autoplay="autoplay"
            loop="loop"
            muted="muted"
            :poster="item.holdImg"
          >
            <source :src="item.fileURL" type="video/mp4" />
          </video>
          <img
            v-if="item.type == 0"
            :src="item.fileURL"
            alt=""
            ref="swiperImg"
          />
        </div>
      </el-carousel-item>
    </el-carousel>

    <!-- <swiper class="my-swiper" v-bind:options="swiperOptions" ref="mySwiper">
      <swiper-slide v-for="(item, index) in swiperList" v-bind:key="index">
        <div class="swiper-item">
          <div class="text-area">
            <div class="title">{{ item.title }}</div>
            <div class="desc">{{ item.desc }}</div>
            <div class="btn">立即使用</div>
          </div>
          <video
            class="swiper-bg"
            autoplay="autoplay"
            loop="loop"
            muted="muted"
            :poster="item.holdImg"
          >
            <source :src="item.img" type="video/mp4" />
          </video>
        </div>
      </swiper-slide>

      <div class="swiper-pagination" slot="pagination"></div>
    </swiper> -->
  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/swiper-bundle.css";
// import banner1 from "../assets/img/banner1.mp4";
// import banner2 from "../assets/img/banner2.mp4";
// import banner3 from "../assets/img/banner3.mp4";

// import banner1Jpg from "../assets/img/banner1.jpg";
// import banner2Jpg from "../assets/img/banner2.jpg";
// import banner3Jpg from "../assets/img/banner3.jpg";

import { swiperListApi } from "@/server/api";

export default {
  components: {
    swiper,
    swiperSlide,
  },
  data() {
    return {
      swiperList: [],
      swiperOptions: {
        loop: true,
        speed: 1000,
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        // 显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true, //允许分页点击跳转
        },
        on: {
          click: (event) => {
            if (event.target.innerText === "立即使用") {
              const realIndex = this.$refs.mySwiper.swiper.realIndex;
              this.clickProject(this.swiperList[realIndex].id);
            }
          },
        },
      },
      carHeight: "1000px",
    };
  },
  async mounted() {
    await this.getSwipperList();
    window.onresize = () => {
      if (this.$refs["swiperVideo"]) {
        this.carHeight = this.$refs["swiperVideo"][0].offsetHeight + "px";
      }
      if (this.$refs["swiperImg"]) {
        this.carHeight = this.$refs["swiperImg"][0].offsetHeight + "px";
      }
    };
    this.$nextTick(() => {
      if (this.$refs["swiperVideo"]) {
        const video = this.$refs["swiperVideo"][0];
        video.oncanplay = () => {
          this.carHeight = video.offsetHeight + "px";
        };
      }
      if (this.$refs["swiperImg"]) {
        const img = this.$refs["swiperImg"][0];
        img.onload = () => {
          this.carHeight = img.offsetHeight + "px";
        };
      }
    });
  },
  methods: {
    clickProject(jumpUrl) {
      // let id = jumpUrl.split("?")[1].split("=")[1];
      // if (id) {
      //   this.util.openProject(id);
      // }
      window.location.href = jumpUrl;
    },
    // 获取轮播主体信息
    async getSwipperList() {
      const data = {
        params: {},
        pageIndex: 1,
        pageSize: 999,
      };
      return swiperListApi(data).then((res) => {
        this.swiperList = res.data.rspData.records.filter(
          (val) => val.dataStatus == 1
        );
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/style/reset.scss";
.banner-wrapper {
  box-sizing: border-box;
  .swiper-item {
    overflow: hidden;
    width: 100%;
    height: fit-content;
    background-size: 100% auto;
    position: relative;
    display: flex;
    justify-content: center;

    img {
      width: 100%;
      height: 100%;
    }

    .swiper-bg {
      width: 100%;
    }

    .text-area {
      padding: 0 $page_width_padding;
      max-width: $banner_max_width;
      box-sizing: border-box;
      position: absolute;
      top: 25%;
      width: 100%;
      color: #393f49;
      z-index: 2;

      .title {
        font-size: 40px;
        font-weight: 600;
      }

      .desc {
        width: 50%;
        font-size: 16px;
        margin-top: 24px;
        text-align: justify;
        line-height: 30px;
        font-weight: 400;
      }

      .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 130px;
        height: 42px;
        background: #c90420;
        margin-top: 36px;
        font-size: 16px;
        font-weight: 600;
        color: rgba(255, 255, 255, 1);
        cursor: pointer;
      }
    }
  }

  .el-carousel {
    &::v-deep .el-carousel__indicators {
      bottom: 20px;
    }

    &::v-deep .is-active {
      .el-carousel__button {
        background: #c90420;
      }
    }
  }
}
</style>
