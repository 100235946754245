<template>
  <div class="jiagou-wraper">
    <TitleStyle
      :title="'产品矩阵'"
      :desc="'多行业大数据经验，挖掘数据价值'"
      :isDark="true"
    ></TitleStyle>
    <div class="part1 part">
      <div class="item" v-for="(item, index) of part1" :key="index">
        <img class="item_bg" :src="juzhenItemBg" alt="" />
        <div class="content">
          <div class="name">{{ item.name }}</div>
          <div class="desc">{{ item.desc }}</div>
        </div>
        <div class="item_tip">
          <img :src="juzhenHover" alt="" />
        </div>
      </div>
    </div>
    <div class="part2 part">
      <div class="item" v-for="(item, index) of part2" :key="index">
        <img class="item_bg" :src="juzhenItemBg" alt="" />
        <div class="content">
          <div class="name">{{ item.name }}</div>
          <div class="desc">{{ item.desc }}</div>
        </div>
        <div class="item_tip">
          <img :src="juzhenHover" alt="" />
        </div>
      </div>
    </div>
    <div class="part3 part">
      <div class="item" v-for="(item, index) of part3" :key="index">
        <img class="item_bg" :src="juzhenItemBg" alt="" />
        <div class="content">
          <div class="name">{{ item.name }}</div>
          <div class="desc">{{ item.desc }}</div>
        </div>
        <div class="item_tip">
          <img :src="juzhenHover" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleStyle from "./TitleStyle.vue";
import juzhenItemBg from "../assets/img/juzhen_item_bg.png";
import juzhenHover from "../assets/img/juzhen_hover.png";

export default {
  components: {
    TitleStyle,
  },
  data: () => {
    return {
      juzhenItemBg,
      juzhenHover,
      part1: [
        {
          name: "D.I API 数据服务平台",
          desc: "数据服务旨在为企业搭建统一的数据服务总线，以数据共享和服务共享为基础，支持0代码将数据表生成API，同时支持将现有的API快速注册至数据服务平台，进行统一的管理和发布",
        },
        {
          name: "D.I Insight 数据可视化平台",
          desc: "轻量化无代码拖拽交互，让每个人都能自助灵活的分析数据，实现数据的图形化转换，让决策更直观可见、有数可依，多终端的数据展示形式，全方位满足用户的数据应用场景",
        },
        {
          name: "D.I Tag 标签画像平台",
          desc: "整合全域数据，识别唯一用户，通过构建标签及画像体系，将数据资产标签化、标签画像价值化，赋能业务实现精细化运营，实现用户洞察与业务增长",
        },
      ],
      part2: [
        {
          name: "D.I Graph 图分析平台",
          desc: `以自研图计算引擎、图深度学习引擎为依托，为用户提供一站式图谱构建、可视化图探索、大规模复杂和深度关联数据挖掘工具。针对金融等多场景，如反洗钱、反欺诈、案件调查、贷前授信、贷后催收等提供基于图的风控解决方案`,
        },
        {
          name: "D.I Ku+ 一站式AI开发平台",
          desc: "企业级一站式AI开发平台，基于MLOps理念提供数据管理、模型开发、模型训练、模型部署、模型监控、算力资源按需调度等全链路能力，实现AI模型全生命周期管理和高效规模化生产，助力企业AI算法研发和应用效能提升",
        },
        {
          name: "D.I Index 指标管理平台",
          desc: "企业级一站式指标管理平台，提供便捷、高效、安全可靠的指标管理服务。帮助企业提供指标数据的统一化管理，同时为业务人员提供指标查询与运营、分析的能力，提升业务人员的数据使用能力",
        },
      ],
      part3: [
        {
          name: "D.I BDP 一站式数据开发管理平台",
          desc: "作为企业数字化基础设施，面向企业用户提供数据集成、存储、开发、管理的一站式服务，帮助数据研发人员快速高效的完成数据研发与资产管理工作，辅助企业科学规划和合理构建全域数据，标准、规范、高效的管理数据，以开放的方式实现企业内部数据共享，充分发挥数据价值",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/style/reset.scss";
.jiagou-wraper {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(26, 33, 45, 1);
  padding: 0 $page_width_padding;
  padding-bottom: 130px;

  ::v-deep .title {
    color: rgba(255, 255, 255, 1);
  }

  ::v-deep .desc {
    color: rgba(255, 255, 255, 1);
  }

  .part {
    width: 100%;
    display: flex;
    max-width: $banner_max_width;

    .item {
      flex-grow: 1;
      margin: 11px;
      background: rgba(255, 255, 255, 1);
      transition: transform 200ms;
      position: relative;

      .content {
        position: absolute;
        padding: 30px;
        z-index: 2;
        .name {
          font-size: 20px;
          font-weight: 600;
          color: rgba(48, 49, 50, 1);
          margin-bottom: 30px;
          position: relative;

          &::before {
            content: " ";
            position: absolute;
            bottom: -10px;
            left: 0;
            width: 60px;
            height: 4px;
            background: #C90420;
          }
        }

        .desc {
          font-size: 14px;
          color: rgba(96, 97, 102, 1);
          text-align: justify;
        }
      }

      .item_bg {
        width: 79px;
        height: 79px;
        position: absolute;
        right: 0;
        bottom: 0;
      }

      .item_tip {
        width: 45px;
        height: 34px;
        background: #C90420;
        box-shadow: 0 10px 23px 0 rgba(242, 39, 12, 0.25);
        position: absolute;
        right: 0;
        top: 32px;
        align-items: center;
        justify-content: center;
        display: none;

        img {
          width: 19px;
        }
      }

      &:hover {
        transform: translateY(-10px);
      }

      &:hover .item_tip {
        display: flex;
      }
    }
  }

  .part1 {
    margin-top: 100px;
    height: 220px;
  }

  .part2 {
    height: 220px;
  }

  .part3 {
    height: 200px;
  }
}
</style>
