function openProject(id) {
  window.location.href = `/pedestal?platformId=${id}`
}

export default {
  install: (app) => {
    app.prototype.util = {
      openProject
    }
  }
}