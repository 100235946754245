<template>
  <div class="bottom-info-wraper">
    <div class="main-info">
      <div class="type-list">
        <div class="type" :key="index" v-for="(type, index) of mainData">
          <div class="name">{{ type.name }}</div>
          <div class="list">
            <div
              class="item"
              v-for="(item, i) of type.list"
              :key="i"
              @click="clickProject(item.id)"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>
      <div class="qr-area">
        <div class="item">
          <img :src="qr1" alt="" />
          <div class="desc">扫码关注我们</div>
        </div>
        <div class="item">
          <img :src="qr2" alt="" />
          <div class="desc">扫码关注我们</div>
        </div>
      </div>
    </div>
    <div class="other-info">
      <div class="list">
        <div class="item">帮助</div>
        <div class="item">隐私</div>
        <div class="item">条款</div>
      </div>
      <div class="info">京东科技 jdt.com.cn 版权所有</div>
    </div>
  </div>
</template>

<script>
import qr1 from "../assets/img/qr1.png";
import qr2 from "../assets/img/qr2.png";

export default {
  props: {
    menuList: Array,
  },
  data: () => {
    return {
      qr1,
      qr2,
      mainData: [
        {
          name: "便捷入口",
          list: [
            { name: "D.I BDP 一站式数据开发与管理平台", id: "74" },
            { name: "D.I API 数据服务平台", id: "76" },
            { name: "D.I insight 数据可视化平台", id: "77" },
            { name: "D.I Tag 标签画像平台", id: "78" },
          ],
        },
        {
          name: "资料中心",
          list: [
            { name: "一站式数据开发与管理平台白皮书", id: "" },
            { name: "数据服务平台白皮书", id: "" },
            { name: "数据可视化平台白皮书", id: "" },
            { name: "标签画像平台白皮书", id: "" },
          ],
        },
        {
          name: "服务与支持",
          list: [
            { name: "咨询服务", id: "" },
            { name: "关于我们", id: "" },
            { name: "加入我们", id: "" },
          ],
        },
      ],
    };
  },
  watch: {
    menuList: {
      handler() {
        this.onMenuListChange();
      },
      immediate: true,
    },
  },
  methods: {
    onMenuListChange() {
      this.mainData[0].list = this.menuList.map((item) => {
        return {
          name: item.modulesName + item.name,
          id: item.id,
        };
      }).splice(0, 4)
    },
    clickProject(id) {
      if (id) {
        this.util.openProject(id);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/style/reset.scss";
.bottom-info-wraper {
  width: 100%;
  box-sizing: border-box;
  padding: 0 $page_width_padding;
  background: rgba(20, 20, 20, 1);

  .main-info {
    width: 100%;
    overflow: auto;
    height: 362px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    padding: 0 10px;
    box-sizing: border-box;
    max-width: $banner_max_width;

    .type-list {
      display: flex;
      color: rgba(255, 255, 255, 1);
      font-size: 12px;
      flex-shrink: 0;
      justify-content: space-between;
      width: calc(100% - 330px);
      box-sizing: border-box;

      .type {
        flex-shrink: 0;
        flex-grow: 1;

        .name {
          font-size: 14px;
          position: relative;
          &::before {
            content: "";
            position: absolute;
            left: 0;
            bottom: -10px;
            width: 27px;
            height: 2px;
            background: rgba(255, 255, 255, 1);
          }
        }

        .list {
          .item {
            margin-top: 38px;
            cursor: pointer;
            color: rgba(202, 202, 202, 1);
          }
        }

        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }

    .qr-area {
      margin-bottom: -10px;
      padding-left: 80px;
      display: flex;
      align-items: center;
      flex-shrink: 0;
      height: 245px;
      border-left: 1px solid rgba(81, 81, 81, 1);

      .item:nth-child(1) {
        margin-right: 40px;
      }

      .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-shrink: 0;
        img {
          width: 100px;
        }

        .desc {
          font-size: 12px;
          color: rgba(255, 255, 255, 1);
          margin-top: 10px;
        }
      }
    }
  }

  .other-info {
    width: 100%;
    height: 88px;
    border-top: 1px solid rgba(81, 81, 81, 1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .list {
      font-size: 12px;
      font-weight: 300;
      color: rgba(255, 255, 255, 1);
      display: flex;
      .item {
        height: 13px;
        border-left: 1px solid rgba(255, 255, 255, 1);
        padding: 0 20px;
        cursor: pointer;
      }

      .item:nth-child(1) {
        border-left: unset;
      }
    }

    .info {
      font-size: 12px;
      font-weight: 300;
      color: rgba(255, 255, 255, 1);
      margin-top: 8px;
    }
  }
}
</style>