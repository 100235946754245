<template>
  <div class="title-style-wraper">
    <div class="title">
      <img class="left" :src="isDark ? titleLeftDark : titleLeft" alt="" />
      <span>{{ title }}</span>
      <img class="right" :src="isDark ? titleRightDark : titleRight" alt="" />
    </div>
    <div class="desc">{{ desc }}</div>
  </div>
</template>

<script>
import titleLeft from "../assets/img/title_left.png";
import titleRight from "../assets/img/title_right.png";

import titleLeftDark from "../assets/img/title_left_dark.png";
import titleRightDark from "../assets/img/title_right_dark.png";

export default {
  props: ["title", "desc", "isDark"],
  data: () => {
    return {
      titleLeft,
      titleRight,
      titleLeftDark,
      titleRightDark,
    };
  },
};
</script>

<style lang="scss" scoped>
.title-style-wraper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 94px;
  padding-top: 100px;
  .title {
    font-size: 34px;
    font-weight: 600;
    color: rgba(48, 48, 48, 1);
    display: flex;
    align-items: center;

    .left {
      width: 140px;
      height: 11px;
    }

    span {
      margin: 0 50px;
    }

    .right {
      width: 140px;
      height: 11px;
    }
  }
  .desc {
    font-size: 18px;
    color: rgba(96, 97, 102, 1);
    margin-top: 8px;
  }
}
</style>