<template>
  <div class="changePassDialog">
    <el-dialog
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      title="修改密码"
      :visible.sync="dialogVisible"
      width="640px"
      :before-close="cancel"
    >
      <el-form
        label-position="right"
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="80px"
        class="demo-ruleForm"
      >
        <el-form-item label="原密码" prop="oldPassWord">
          <el-input placeholder="请输入当前密码" v-model="ruleForm.oldPassWord" show-password></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="pass">
          <el-input placeholder="请输入修改后密码" v-model="ruleForm.pass" show-password></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="checkPass">
          <el-input placeholder="请再次输入修改后密码" v-model="ruleForm.checkPass" show-password></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button style="min-width: 88px" @click="cancel">取 消</el-button>
        <el-button
          style="min-width: 88px"
          class="submit-btn"
          :loading="loading"
          type="primary"
          @click="submitForm('ruleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { changePwdApi } from "@/server/api";
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    var validatePass = (rule, value, callback) => {
      let regExp =
        /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)(?=.*?[*?!&￥$%^#,./@";:><\[\]}{\-=+_\\|》《。，、？’‘“”~ `]).*$/;
      if (value === "") {
        callback(new Error("请输入修改后密码"));
      } else if (value.length < 8) {
        callback(
          new Error("密码需包含大小写字母、数字、特殊字符，8至30个字符")
        );
      } else if (value.length > 30) {
        callback(new Error("请输入8-30个字符以内"));
      } else if (!regExp.test(value)) {
        callback(
          new Error("密码需包含大小写字母、数字、特殊字符，8至30个字符")
        );
      } else if (value === this.ruleForm.oldPassWord) {
        callback(new Error("新密码和旧密码不能保持一致"));
      } else {
        callback();
      }
    };

    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入修改后密码"));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      loading: false,
      readonlyFlag: true,
      ruleForm: {
        oldPassWord: "",
        pass: "",
        checkPass: "",
      },
      rules: {
        oldPassWord: [
          { required: true, message: "请输入当前密码", trigger: "blur" },
        ],
        pass: [{ required: true, validator: validatePass, trigger: "blur" }],
        checkPass: [
          { required: true, validator: validatePass2, trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    cancel() {
      this.$emit("update:dialogVisible", false);
    },
    keyupEnter() {
      document.onkeydown = (e) => {
        // let body = document.getElementsByClassName("submit-btn");
        if (e.code == "Enter" || e.code == "enter") {
         // match(此处应填写文件在浏览器中的地址，如 '/home/index')
          this.submitForm("ruleForm");
        }
      };
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          let params = {
            originPassword: this.ruleForm.oldPassWord,
            newPassword: this.ruleForm.pass,
          };
          changePwdApi(params)
            .then((res) => {
              let message = "";
              //  window.location.href = localStorage.getItem('login_url');
              if (res.data.result == 0) {
                this.$message({
                  type: "error",
                  message: res.data.rspMsg
                });
              }
              if (res.data.result == 1) {
                switch (res.data.rspData) {
                  case 1:
                    this.$message({
                      type: "success",
                      message: "修改密码成功",
                    });
                    window.location.href = localStorage.getItem("login_url");
                    break;
                  case -1:
                    message = "旧密码不正确";
                    break;
                  case -2:
                    message = "重试次数超过3次";
                    break;
                  case -3:
                    message = "新密码和旧密码不能保持一致";
                    break;
                  default:
                    message = "修改密码接口返回错误！";
                }
                this.$message({
                  type: "error",
                  message: message,
                });
              }
              this.loading = false;
            })
            .catch((err) => {
              this.loading = true;
              // this.$message({
              //   type: "error",
              //   duration: 0,
              //   message: err.rspMsg,
              // });
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style scoped>
.changePassDialog >>> .el-dialog__header {
  padding: 0 24px;
  height: 56px;
  line-height: 56px;
  border-bottom: 1px solid #e3e5ec;
}
.changePassDialog  >>>  .el-dialog__body {
  padding: 24px;
}
.changePassDialog  >>> .el-form-item {
  margin-bottom: 16px;
}
.changePassDialog >>> .el-dialog__footer {
  height: 56px;
  line-height: 56px;
  border-top: 1px solid #e3e5ec;
  display: flex;
  align-items: center;
  padding: 0px 24px;
  flex-direction: row-reverse;
}
.changePassDialog >>> .el-dialog__header {
  font-weight: 500;
}
.changePassDialog >>> .el-dialog__headerbtn {
  top: 0;
  right: 24px;
  height: 56px;
}
.changePassDialog >>> .el-dialog__close {
  font-size: 16px;
}

</style>
