<template>
  <div class="slogan-wraper">
    <img :src="slogan" alt="">
  </div>
</template>

<script>
import slogan from "../assets/img/slogan.png";

export default {
  data: () => {
    return {
      slogan
    }
  }
}
</script>

<style lang="scss" scoped>
.slogan-wraper {
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;

  img {
    width: 101%;
    vertical-align: top;
  }
}
</style>