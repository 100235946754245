var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home",style:({ opacity: _vm.homeOpacity })},[_c('img',{staticStyle:{"display":"none"},attrs:{"src":require("../assets/img/projectRight.jpg")}}),_c('img',{staticStyle:{"display":"none"},attrs:{"src":require("../assets/img/projectLeft.jpg")}}),_c('Notice'),_c('div',{staticClass:"top-area-wrap"},[_c('TopBar',{ref:"topCom",attrs:{"userInfo":_vm.userInfo,"isBlack":_vm.projectMenuShow},on:{"menuMouseover":_vm.menuMouseover,"menuMouseleave":_vm.menuMouseleave}}),_c('ProjectMenu',{directives:[{name:"show",rawName:"v-show",value:(_vm.projectMenuShow),expression:"projectMenuShow"}],staticClass:"project-menu-area",attrs:{"hiddenFun":_vm.hiddenProjectMenu,"stopFun":_vm.stopFun,"menuList":_vm.menuList}})],1),_c('Banner',{directives:[{name:"show",rawName:"v-show",value:(_vm.homeModule.轮播图 == 1),expression:"homeModule.轮播图 == 1"}]}),(
      JSON.stringify(_vm.homeModule) != '{}' &&
      (_vm.homeModule.产品架构 != 0 ||
        _vm.homeModule.产品优势 != 0 ||
        _vm.homeModule.产品矩阵 != 0)
    )?_c('ProjectNav',{attrs:{"homeModule":_vm.homeModule,"menuCode":_vm.currProjectCode},on:{"update:menuCode":[function($event){_vm.currProjectCode=$event},_vm.selectProject],"update:menu-code":function($event){_vm.currProjectCode=$event}}}):_vm._e(),(_vm.homeModule.产品架构 == 1)?_c('Jiagou',{directives:[{name:"scrollselect",rawName:"v-scrollselect",value:({
      callback: function () { return _vm.scrollTrigger('jiagou'); },
    }),expression:"{\n      callback: () => scrollTrigger('jiagou'),\n    }"}],ref:"jiagou"}):_vm._e(),_c('Youshi',{directives:[{name:"show",rawName:"v-show",value:(_vm.homeModule.产品优势 == 1),expression:"homeModule.产品优势 == 1"},{name:"scrollselect",rawName:"v-scrollselect",value:({
      callback: function () { return _vm.scrollTrigger('youshi'); },
    }),expression:"{\n      callback: () => scrollTrigger('youshi'),\n    }"}],ref:"youshi"}),_c('Juzhen',{directives:[{name:"show",rawName:"v-show",value:(_vm.homeModule.产品矩阵 == 1),expression:"homeModule.产品矩阵 == 1"},{name:"scrollselect",rawName:"v-scrollselect",value:({
      callback: function () { return _vm.scrollTrigger('juzhen'); },
    }),expression:"{\n      callback: () => scrollTrigger('juzhen'),\n    }"}],ref:"juzhen"}),_c('Anli',{directives:[{name:"show",rawName:"v-show",value:(_vm.homeModule.平台案例 == 1),expression:"homeModule.平台案例 == 1"}]}),_c('Slogan',{directives:[{name:"show",rawName:"v-show",value:(_vm.homeModule.页尾 == 1),expression:"homeModule.页尾 == 1"}]}),_c('BottomInfo',{directives:[{name:"show",rawName:"v-show",value:(_vm.homeModule.页尾 == 1),expression:"homeModule.页尾 == 1"}],attrs:{"menuList":_vm.menuList}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }