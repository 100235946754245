<template>
  <div class="anli-wraper" :style="{ backgroundImage: 'url(' + anliBg + ')' }">
    <TitleStyle
      :title="'平台案例'"
      :desc="'期待您的选择，共建智能数据“黑土地”'"
    ></TitleStyle>

    <div class="anli-list anli-list1">
      <div class="anli-item" v-for="(item, index) of anliList1" :key="index">
        <img :src="item" alt="" />
      </div>
    </div>
    <div class="anli-list">
      <div class="anli-item" v-for="(item, index) of anliList2" :key="index">
        <img :src="item" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import TitleStyle from "./TitleStyle.vue";
import anliBg from "../assets/img/anli_bg.png";

import anli1_1 from "../assets/img/1-1.png";
import anli1_2 from "../assets/img/1-2.png";
import anli1_3 from "../assets/img/1-3.png";
import anli1_4 from "../assets/img/1-4.png";
import anli1_5 from "../assets/img/1-5.png";
import anli2_1 from "../assets/img/2-1.png";
import anli2_2 from "../assets/img/2-2.png";
import anli2_3 from "../assets/img/2-3.png";
import anli2_4 from "../assets/img/2-4.png";
import anli2_5 from "../assets/img/2-5.png";

export default {
  components: {
    TitleStyle,
  },
  data: () => {
    return {
      anliList1: [anli1_1, anli1_2, anli1_3, anli1_4, anli1_5],
      anliList2: [anli2_1, anli2_2, anli2_3, anli2_4, anli2_5],
      anliBg,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/style/reset.scss";
.anli-wraper {
  padding: 0 $page_width_padding;
  box-sizing: border-box;
  padding-bottom: 130px;
  background-size: 100% 100%;
  .anli-list {
    display: flex;
    justify-content: center;
    max-width: $banner_max_width;
    margin: 0 auto;
    .anli-item {
      width: 256px;
      flex-grow: 1;
      height: 130px;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0 2px 2px 0 rgba(190, 194, 204, 0.38);
      margin: 14px;
      transition: transform 200ms, box-shadow 200ms;
      display: flex;
      align-items: center;

      img{
        width: 100%;
      }

      &:hover {
        box-shadow: 40px 20px 141px 0 rgba(190, 194, 204, 1);
        transform: translateY(-8px);
      }
    }
  }

  .anli-list1 {
    margin-top: 90px;
  }
}
</style>