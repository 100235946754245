<template>
  <div
    class="youshi-wraper"
    :style="{ backgroundImage: 'url(' + youshiBg + ')' }"
  >
    <TitleStyle
      :title="'产品优势'"
      :desc="'数据领域深耕者，打造贴心大数据平台'"
    ></TitleStyle>
    <div class="youshi-list">
      <div class="youshi-item" v-for="(item, index) of youshiList" :key="index">
        <div class="tag"></div>
        <img :src="item.img" alt="" />
        <div class="name">{{ item.name }}</div>
        <div class="desc">{{ item.desc }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleStyle from "./TitleStyle.vue";

import quanyu from "../assets/img/quanyu.png";
import linghuo from "../assets/img/linghuo.png";
import zhineng from "../assets/img/zhineng.png";
import anquan from "../assets/img/anquan.png";
import youshiBg from "../assets/img/youshi_bg.png";

export default {
  components: {
    TitleStyle,
  },
  data: () => {
    return {
      youshiBg,
      youshiList: [
        {
          name: "全域",
          desc: "提供全域数据资产管理所需的全套大数据基础设施",
          img: quanyu,
        },
        {
          name: "灵活",
          desc: "灵活适配各类大数据基础组件和客户个性化环境",
          img: linghuo,
        },
        {
          name: "智能",
          desc: "提供智能化数据研发管理和运营能力",
          img: zhineng,
        },
        { name: "安全", desc: "提供数据全生命周期的安全管控策略", img: anquan },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/style/reset.scss";
.youshi-wraper {
  width: 100%;
  box-sizing: border-box;
  padding: 0 $page_width_padding;
  padding-bottom: 170px;
  background-size: 100% 100%;

  .youshi-list {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    margin-top: 130px;
    max-width: $banner_max_width;

    .youshi-item {
      width: 0px;
      flex-grow: 1;
      height: 452px;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0 2px 20px 0 rgba(190, 194, 204, 0.38);
      margin: 0 10px;
      transition: transform 200ms;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;

      .tag {
        width: 100%;
        height: 4px;
        background: #c90420;
        position: absolute;
        top: 0;
        left: 0;
        display: none;
      }

      img {
        width: 170px;
        height: 156px;
        margin-top: 80px;
      }

      .name {
        margin-top: 30px;
        font-size: 20px;
        font-weight: 600;
        color: rgba(48, 49, 50, 1);
        width: 248px;
        height: 40px;
        border-bottom: 2px solid rgba(210, 211, 215, 1);
        display: flex;
        justify-content: center;
      }

      .desc {
        width: 248px;
        font-size: 14px;
        color: rgba(96, 97, 102, 1);
        margin-top: 28px;
        text-align: center;
      }

      &:hover {
        transform: scale(1.15);
        z-index: 1;

        .tag {
          display: block;
        }
      }
    }
  }
}
</style>
