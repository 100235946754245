function scrollCallback(el, callback) {
    if (trigger(el)) {
        callback()
    }
}

function trigger(childDom) {
    let scrollTop = 0
    const { top: childTop } = childDom.getBoundingClientRect()

    return scrollTop + 20 > childTop && scrollTop - 200 < childTop
}

let scrollCallbackFun

export default {
    install: (app) => {
        app.directive('scrollselect', {
            bind(el, binding) {
                const { callback } = binding.value
                scrollCallbackFun = () => scrollCallback(el, callback)
                window.addEventListener('scroll', scrollCallbackFun)
            },
            unbind() {
                window.removeEventListener('scroll', scrollCallbackFun)
            },
        })
    }
}