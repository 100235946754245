<template>
  <div class="top-bar-wraper" :class="{ blackColor: isBlack }">
    <div class="logo-area">
      <!-- v-show="!isBlack" -->
      <img :src="iconMess ? iconMess.fileURL : ''" alt="" />
      <!-- <img v-show="isBlack" :src="logoBlack" alt="" /> -->
      <span class="pro-name" :class="isBlack ? 'isBlackProName' : ''">{{
        iconMess ? iconMess.programName : ""
      }}</span>
    </div>

    <!-- <NavMenu
      :menuList="menuList"
      v-slot="{ data }"
      :menuCode="currMenuCode"
      @update:menuCode="selectMenu"
      @menuMouseover="menuMouseover"
      @menuMouseleave="menuMouseleave"
    >
      {{ data.name }}
    </NavMenu> -->

    <div class="top-btn home" @click="linkhome">首页</div>
    <div
      class="top-btn produce home"
      :class="{ 'produce-active': isSelectedProduce }"
      @mouseover="menuMouseover('produce')"
      @mouseleave="menuMouseleave('produce')"
    >
      产品中心
      <img :src="isSelectedProduce ? up : blackDown" alt="" />
    </div>
    <div class="top-btn home" @click="lickhelp">帮助中心</div>

    <div class="holder"></div>
    <div class="top-btn liucheng" @click="linkLiucheng">流程中心</div>
    <div v-if="!isLogin" @click="goLogin" class="login-area">登录</div>

    <el-dropdown v-if="isLogin && userInfo">
      <div class="user-mess">
        <p class="head-portrait">
          <img src="../assets/img/user-default.png" alt="" />
        </p>
        <p class="name">{{ userInfo.pin }}</p>
      </div>

      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item>
          <div @click="changePassWord" class="option-item">修改密码</div>
        </el-dropdown-item>
        <el-dropdown-item>
          <div @click="signOut" class="option-item">退出登录</div>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>

    <!-- 修改密码弹框 -->
    <changePassWordDialog
      v-if="dialogVisible"
      :dialogVisible.sync="dialogVisible"
    />
  </div>
</template>
<script>
import logo from "../assets/img/logo.png";
import logoBlack from "../assets/img/logo_black.png";
import NavMenu from "./NavMenu.vue";
import blackDown from "../assets/img/down-black.png";
import up from "../assets/img/up.png";
import changePassWordDialog from "@/components/changePwd.vue";

import { signOut, platfromMessApi } from "@/server/api";
export default {
  props: ["isBlack", "userInfo"],
  name: "TopMenu",
  components: {
    NavMenu,
    changePassWordDialog,
  },
  data: () => {
    return {
      dialogVisible: false,
      isSelectedProduce: false,
      blackDown,
      up,
      logo,
      logoBlack,
      currMenuCode: "home",
      menuList: [
        { name: "首页", code: "home" },
        { name: "产品", code: "produce", noSelect: true },
        { name: "帮助中心", code: "help", noSelect: true },
      ],
      iconMess: {},
      isLogin: false,
    };
  },
  watch: {
    userInfo: {
      handler(val) {
        if (val && val.pin) {
          this.isLogin = true;
          return val;
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    // getUserInfoFn() {
    //   getUserInfo()
    //     .then((res) => {
    //       this.userInfo = res.data.rspData;
    //       localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
    //     })
    // },
    selectedProduce(tag) {
      this.isSelectedProduce = tag;
    },
    selectMenu(code) {
    },
    menuMouseover(code) {
      this.$emit("menuMouseover", code);
      this.isSelectedProduce = true;
    },
    menuMouseleave(code) {
      this.$emit("menuMouseleave", code);
    },
    lickhelp() {
      this.$router
        .push({
          path: "/help",
        })
        .catch(() => {});
    },
    linkhome() {
      this.$router
        .push({
          path: "/",
        })
        .catch(() => {});
    },
    linkLiucheng() {
      window.open("/pedestal/bigdataos-approve/Home");
    },
    changePassWord() {
      this.dialogVisible = true;
    },
    goLogin() {
      window.location.href = localStorage.getItem("login_url");
    },
    signOut() {
      this.$confirm("确定退出登录吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        closeOnClickModal: false,
      })
        .then(() => {
          signOut();
          // await signOut().catch((err) => {
          //   window.location.href = window.location.href;
          // });
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "取消退出",
          // });
        });
    },
    async getIconMessFn() {
      const res = await platfromMessApi();
      this.iconMess = res.data.rspData;
      this.changeIconTitle();
    },
    // 动态修改浏览器导航栏图标和文字
    changeIconTitle() {
      const changeFavicon = (link) => {
        let $favicon = document.querySelector('link[rel="icon"]');
        if ($favicon !== null) {
          $favicon.href = link;
        } else {
          $favicon = document.createElement("link");
          $favicon.rel = "icon";
          $favicon.href = link;
          document.head.appendChild($favicon);
        }
      };
      let iconUrl = this.iconMess.labelURL; // 图片地址
      changeFavicon(iconUrl); // 动态修改网站图标
      let title = this.iconMess.programName; // 网站标题
      document.title = title; // 动态修改网站标题
    },
  },
  mounted() {
    // this.getUserInfoFn()
    this.getIconMessFn();
  },
};
</script>

<style scoped lang="scss">
@import "../assets/style/reset.scss";
.top-bar-wraper {
  width: 100%;
  box-sizing: border-box;
  height: 68px;
  // line-height: 68px;
  background: rgba(255, 255, 255, 0.5);
  // background-color: #000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0 $page_width_padding;
  z-index: 3;
  color: #393f49;

  .logo-area {
    display: flex;
    align-items: center;
    margin-left: 2px;
    margin-right: 60px;

    img {
      height: 38px;
      margin-right: 10px;
    }
    .pro-name {
      color: #393f49;
      font-weight: 600;
      font-size: 20px;
    }
    .isBlackProName {
      color: #4e4e4e;
    }
  }

  .top-btn {
    font-size: 16px;
    font-weight: normal;
    // color: #ffffff;
    padding: 0 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .home {
    position: relative;

    &:hover {
      font-weight: 600;

      &::before {
        content: " ";
        position: absolute;
        width: 60%;
        height: 2px;
        background: #c90420;
        bottom: 0;
      }
    }
  }

  .liucheng {
    font-size: 13px;
    padding: 0;
    margin: 0 40px;
    &:hover {
      font-weight: 600;
      color: #c90420;
    }
  }

  .produce {
    img {
      width: 8px;
      margin-left: 3px;
    }
  }

  .produce-active {
    color: #c90420 !important;
    font-weight: 600;
  }

  .help {
    &:hover {
      color: #c90420;
      font-weight: 600;
    }
  }

  .holder {
    flex-grow: 1;
  }

  .login-area {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 84px;
    height: 32px;
    background: #c90420;
    border-radius: 2px;
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
  }

  .user-mess {
    display: flex;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
    overflow: hidden;
    .head-portrait {
      width: 32px;
      height: 32px;

      border-radius: 50%;
      margin-right: 10px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .name {
      // margin-top: 10px;
    }
  }
}
.el-dropdown-menu {
  .option-item {
    min-width: 50px;
  }
}

.blackColor {
  background: #ffffff;
  height: 68px;

  .top-btn {
    color: rgba(48, 48, 48, 1);
  }
}
</style>
