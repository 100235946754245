export default {
    install: (app) => {
        app.directive('position', {
            bind(el) {
                el.style.visibility = 'hidden'
                el.style.position = 'fixed'
            },
            inserted(el) {
                el.dataset.display = document?.defaultView?.getComputedStyle(el, null).display
            },
            update(el, binding) {
                const { value } = binding
                el.style.visibility = 'unset'
                el.style.position = 'fixed'

                if (!value) {
                    el.style.display = 'none'
                } else {
                    let x = value.x
                    let y = value.y
                    el.style.display = el.dataset.display
                    el.style.left = x + 'px'
                    el.style.top = y + 'px'
                }
            }
        })
    }
}